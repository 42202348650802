import React from 'react';
import "./OurOutlets.scss";
import outlets_map from "../../assets/images/outlets_map.png";

const OurOutlets = () => {


  return (
    <div className='outlets-section'>
      <div className="outlets-container">
      <div className="centered-container">
      <div className="title-style">
        <span className="prefixTextBold">Our</span>{" "}
        <span className="middleTextBold">OutLets</span>{" "}
        <span className="backgroundStyle"></span>
      </div>
    </div>
        <div className="outlets_text">
        We currently have 11 Franchise Outlets <br />across Delhi, NCR & UP
        </div>
        <div className="bottom-section">
        <div className="outlets_card-section">
            <div className="card">
                <div className="card_icon">🔊</div>
                <div className="card_text">Explore all our outlets</div>
            </div>
            <div className="card">
                <div className="card_icon">📍</div>
                <div className="card_text">Locate the Nearest Outlet</div>
            </div>
        </div>
        <div className="outlets-map">
            <img src={outlets_map} alt='map' />
        </div>
        </div>
      </div>
    </div>
  )
}

export default OurOutlets
