import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import HomePage from '../Pages/HomePage/HomePage'
import LocationPage from '../Pages/LocationPage/LocationPage';
import Policy from '../Components/Policy/Policy';

const Routers = () => {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
    
  return (
    <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/policy' element={<Policy />} />
        <Route path='/location' element={<LocationPage />} />
    </Routes>
  )
}

export default Routers
