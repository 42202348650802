import React from "react";
import "./BrandGrowth.scss";
import growth_graph from "../../assets/images/growth_graph.png";
import { CustomButton as Button } from "../../CommonComponents/button/Button";

const BrandGrowth = () => {
  return (
    <div className="growth-section">
      <div className="growth-container max-width">
         <div className="centered-container">
      <div className="title-style">
        <span className="prefixTextBold">Brand</span>{" "}
        <span className="middleTextBold">Growth</span>{" "}
        <span className="backgroundStyle"></span>
      </div>
    </div>
        <div className="growth_text">
          "Experience our exponential growth journey in just 08 months, from{" "}
          <br />
          crafting each franchise partner's success to producing 2,00,000 <br />
          momos per month. Join us as we soar higher together!"
        </div>
        <div className="growth-graph">
          <img src={growth_graph} alt="map" />
        </div>
        <div className="growth_btn-section">
          <div className="left_side">
            Join us on this incredible journey! and <br />become part of The Momos
            Mafia Family
          </div>
          <div className="right_side">
            <Button
              title="Apply For Franchise"
              customBtnClass="primaryBtn"
             
            />
            <Button
              title="Book a Meeting"
              customBtnClass="SecondaryBtn"
              
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandGrowth;
