import React from "react";
import "./ProductRange.scss";
import img1 from "../../assets/images/product-img1.png";
import img2 from "../../assets/images/product-img2.png";
import img3 from "../../assets/images/product-img3.png";
import img4 from "../../assets/images/product-img4.png";
import img5 from "../../assets/images/product-img5.png";
import img6 from "../../assets/images/product-img6.png";
import img7 from "../../assets/images/product-img7.png";
import menu from "../../assets/images/menu-card.png";
import { CustomButton as Button} from "../../CommonComponents/button/Button";

const data = [
  {
    id: 1,
    img: img1,
    text: "Vegetable Momo",
  },
  {
    id: 2,
    img: img2,
    text: "Chicken Momo",
  },
  {
    id: 3,
    img: img3,
    text: "Cheesy-Corn Momo",
  },
  {
    id: 4,
    img: img4,
    text: "Soyabean Momo",
  },
  {
    id: 5,
    img: img5,
    text: "Spring Rolls",
  },
  {
    id: 6,
    img: img6,
    text: "Kurkure Momo",
  },
  {
    id: 7,
    img: img7,
    text: "Paneer Momos",
  },
];

const ProductRange = () => {
  return (
    <div className="product-range-section">
      <div className="product-range-container max-width">
        
        <div className="centered-container">
      <div className="title-style">
        <span className="prefixTextBold">Product </span>{" "}
        <span className="middleTextBold">Range & Menu</span>{" "}
        <span className="backgroundStyle"></span>
      </div>
    </div>
        <div className="product-card-section">
          {data.map((item) => (
            <div
              className={`card card-${item.id}`}
              style={{
                backgroundImage: `url(${item.img})`
              }}
            >
              <div className="text-container">{item.text}</div>
            </div>
          ))}
        </div>
        <div className="product-text">
          26 Variety{" "}
          <span>
            of Momos and Spring Rolls to Satisfy Every Customer Craving 🥟🌯
          </span>
        </div>
        <div className="product-table">
          <table width="100%" align="center" cellspacing="0">
            <thead>
              <tr>
                <td>Base Momo</td>
                <td>Steam</td>
                <td>Fried</td>
                <td>Peri-Peri</td>
                <td>Gravy</td>
                <td>Kurkure</td>
                <td>Tandoori</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Veg</td>
                <td>✅</td>
                <td>✅</td>
                <td>✅</td>
                <td>✅</td>
                <td>✅</td>
                <td>✅</td>
              </tr>
              <tr>
                <td>Soya</td>
                <td>✅</td>
                <td>✅</td>
                <td>✅</td>
                <td>✅</td>
                <td>✅</td>
                <td>✅</td>
              </tr>
              <tr>
                <td>Paneer</td>
                <td>✅</td>
                <td>✅</td>
                <td>✅</td>
                <td>✅</td>
                <td>✅</td>
                <td>✅</td>
              </tr>
              <tr>
                <td>Cheesy Corn</td>
                <td>✅</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Chicken</td>
                <td>✅</td>
                <td>✅</td>
                <td>✅</td>
                <td>✅</td>
                <td>✅</td>
                <td>✅</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="product-card-btn">
          <div className="card-btn">
            <span>24 unique</span>{" "}
            Flavours of momo
          </div>
          <div className="card-btn">
            <span>Spring Rolls also</span>{" "}
            available
          </div>
          <div className="card-btn">
            <span>Steam | Fried | Kurkure</span>{" "}
            Platters
          </div>
        </div>
        <div className="menu-card">
          <img src={menu} alt="menu" width="100%" />
        </div>
        <div className="button-section">
          <Button title="Apply For Franchise" customBtnClass="primaryBtn" />
          <Button title="Book a Meeting" customBtnClass="SecondaryBtn"  />
        </div>
      </div>
    </div>
  );
};

export default ProductRange;
