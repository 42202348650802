import React, { useState } from "react";
import { Layout, Dropdown, Space } from "antd";
import "./Header.scss";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../assets/images/momo_logo.svg";
import dropdownIcon from "../../assets/images/dropdown_icon.svg";
import {CustomButton as Button} from "../button/Button";

const { Header } = Layout;

const AllProducts = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const items = [
    {
      key: "0",
      label: (
        <NavLink to={``} className="item-text">
          Item1
        </NavLink>
      ),
    },
    {
      key: "1",
      label: (
        <NavLink to={``} className="item-text">
          Item2
        </NavLink>
      ),
    },
  ];
  return (
    <Dropdown
      className="all-product-dropdown"
      placement="bottom"
      menu={{
        items,
      }}
      visible={openDropdown}
      onVisibleChange={(status) => setOpenDropdown(status)}
    >
      {openDropdown ? (
        <Space className="space" style={{ color: "#007050" }}>
          All Products
          <img src={dropdownIcon} alt="arrow-icon" />
        </Space>
      ) : (
        <Space className="space">
          All Products
          <img src={dropdownIcon} alt="arrow-icon" />
        </Space>
      )}
    </Dropdown>
  );
};
const Services = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const items = [
    {
      key: "0",
      label: (
        <NavLink to={``} className="item-text">
          Item1
        </NavLink>
      ),
    },
    {
      key: "1",
      label: (
        <NavLink to={``} className="item-text">
          Item2
        </NavLink>
      ),
    },
  ];
  return (
    <Dropdown
      className="all-product-dropdown"
      placement="bottom"
      menu={{
        items,
      }}
      visible={openDropdown}
      onVisibleChange={(status) => setOpenDropdown(status)}
    >
      {openDropdown ? (
        <Space className="space" style={{ color: "#007050" }}>
          Services
          <img src={dropdownIcon} alt="arrow-icon" />
        </Space>
      ) : (
        <Space className="space">
          Services
          <img src={dropdownIcon} alt="arrow-icon" />
        </Space>
      )}
    </Dropdown>
  );
};
const Teams = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const items = [
    {
      key: "0",
      label: (
        <NavLink to={``} className="item-text">
          Item1
        </NavLink>
      ),
    },
    {
      key: "1",
      label: (
        <NavLink to={``} className="item-text">
          Item2
        </NavLink>
      ),
    },
  ];
  return (
    <Dropdown
      className="all-product-dropdown"
      placement="bottom"
      menu={{
        items,
      }}
      visible={openDropdown}
      onVisibleChange={(status) => setOpenDropdown(status)}
    >
      {openDropdown ? (
        <Space className="space" style={{ color: "#007050" }}>
          Teams
          <img src={dropdownIcon} alt="arrow-icon" />
        </Space>
      ) : (
        <Space className="space">
          Teams
          <img src={dropdownIcon} alt="arrow-icon" />
        </Space>
      )}
    </Dropdown>
  );
};
const Company = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const items = [
    {
      key: "0",
      label: (
        <NavLink to={``} className="item-text">
          Item1
        </NavLink>
      ),
    },
    {
      key: "1",
      label: (
        <NavLink to={``} className="item-text">
          Item2
        </NavLink>
      ),
    },
  ];
  return (
    <Dropdown
      className="all-product-dropdown"
      placement="bottom"
      menu={{
        items,
      }}
      visible={openDropdown}
      onVisibleChange={(status) => setOpenDropdown(status)}
    >
      {openDropdown ? (
        <Space className="space" style={{ color: "#007050" }}>
          Company
          <img src={dropdownIcon} alt="arrow-icon" />
        </Space>
      ) : (
        <Space className="space">
          Company
          <img src={dropdownIcon} alt="arrow-icon" />
        </Space>
      )}
    </Dropdown>
  );
};

export const CustomHeader = () => {
  const navigate = useNavigate();

  return (
    <Header className="header-section non-mobile">
      <div className="header-container">
        <div className="left_side">
          <div className="logo" onClick={() => navigate('/')} style={{cursor: "pointer"}}>
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="right_side">
          <div className="space">AllProducts</div>
          <div className="space">Services</div>
          <div className="space">Teams</div>
          <div className="space" >Company</div>
          <div className="space" onClick={() => navigate('/policy')}>Policy</div>
          <Button title= "Apply For Franchise" customBtnClass="primaryBtn" width={"191.6px"} height={"38px"} />
        </div>
      </div>
    </Header>
  );
};
