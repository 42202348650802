import { Col, Row } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import address_icon from "../../assets/images/location/adress_icon.svg";
import landmark_icon from "../../assets/images/location/landmark_icon.svg";
import time_icon from "../../assets/images/location/openhour_icon.svg";
import map_icon from "../../assets/images/location/map_icon.svg";



const CardItem = ({ data }) => {
  console.log(data);
  return (
    <div className="Card-section">
      <Row gutter={[18, 18]}>
        {data.map((item) => (
          <Col xs={24} md={24} lg={12} key={item.id}>
            <div className={`card card_${item.id}`}>
                
              
                <img src={item.src} alt="item_img" className="left_side" />
           
              <div className="right_side">
                <div className="cart_details">
                  <div className="cart_id-section">
                    <div className="cart_id">
                    The Momos Mafia - {item.cart_id}
                    </div>
                    <div className="cart_away">{item.away} km away</div>
                  </div>
                  <div className="cart_name">{item.cart_name}</div>
                  <div className="cart_address"> 📍 <span>{item.address}</span></div>
                  <div className="cart_landmark"><img src={landmark_icon} alt="landmark_icon" /> {item.landmark}</div>
                  <div className="cart_time"><img src={time_icon} alt="time_icon" /> {item.Opening_Hours}</div>
                  <div className="direction_btn">
                <NavLink to={"item.direction"}>
                    <img src={map_icon} alt="map-logo" />
                 Directions
                </NavLink>
              </div>
                </div>
                <div className="when_started">Started {item.started} months ago</div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default CardItem;
