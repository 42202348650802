import React, { useEffect, useState } from 'react'
import axios from 'axios';
import "./SocialMedia.scss";
import { Col, Row } from 'antd';
import img1 from "../../assets/images/offline-marketing-img-1.jpg";
import img2 from "../../assets/images/offline-marketing-img-2.jpg";
import img3 from "../../assets/images/offline-marketing-img-3.jpg";




const SocialMedia = () => {

    const [posts, setPosts] = useState([]);
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          `https://graph.instagram.com/me/media?fields=id,media_url,media_type,permalink,timestamp&access_token=${process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN}`
        );
        setPosts(response.data.data);
      } catch (error) {
        console.error('Error fetching Instagram posts:', error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <div className='socialmedia-section'>
        <div className="socialmedia-container max-width">
        
    <div className="instapost-container">
    <div className="centered-container">
      <div className="title-style">
        <span className="prefixTextBold">Social Media</span>{" "}
        <span className="middleTextBold">Brand Management</span>{" "}
        <span className="backgroundStyle"></span>
      </div>
    </div>
    <Row gutter={[4, 4]} className="posts">
        {posts.map((post) => (
          <Col xs={6} lg={4} key={post.id} className="post">
            {console.log(post)}
            {/* <img src={post.media_url} alt={post.caption} /> */}
            {/* {post.media_type === 'IMAGE' ? (
              <img src={post.media_url} alt={post.caption} />
            ) : post.media_type === 'VIDEO' ? (
              <video controls>
                <source src={post.media_url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : null} */}
             {post.media_type === 'IMAGE' && (
              <img src={post.media_url} alt={post.caption} />
            )}
            {post.media_type === 'VIDEO' && (
              <video controls>
                <source src={post.media_url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            {post.media_type === 'CAROUSEL_ALBUM' && (
              <div>
                {post.carousel_media.map((media) => (
                  <img key={media.id} src={media.media_url} alt={post.caption} />
                ))}
              </div>
            )}
            {post.media_type === 'IGTV' && (
              <video controls>
                <source src={post.media_url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </Col>
        ))}
      </Row>
    </div>
    <div className="offlineMarketing-container">
    <div className="centered-container">
      <div className="title-style">
        <span className="prefixTextBold">Offline</span>{" "}
        <span className="middleTextBold">Marketing Campaigns</span>{" "}
        <span className="backgroundStyle"></span>
      </div>
    </div>
    <div className="marketing_cards">
    <img src={img1} className='card' alt='img1' />
    <img src={img2} className='card' alt='img2' />
    <img src={img3} className='card' alt='img3' />
    </div>
    </div>
        </div>
      
    </div>
  )
}

export default SocialMedia
