import React, { useState } from "react";
import "./AboutTheBrand.scss";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import logo from "../../assets/images/momosmafia.png";

const AboutTheBrand = () => {
  const [counterState, setCounterState] = useState(false);

  return (
    <div className="about-section">
      <div className="about-container max-width">
        <div className="centered-container">
      <div className="title-style">
        <span className="prefixTextBold">About</span>{" "}
        <span className="middleTextBold">The Brand</span>{" "}
        <span className="backgroundStyle"></span>
      </div>
    </div>
        <div className="momo-logo">
          <img className="logo" src={logo} alt="logo" />
        </div>
        <div className="about_text">
          It all started with a vision to empower small and medium-sized food
          entrepreneurs, enabling them to kickstart their food business with
          minimal investment. Additionally, it aims to offer business
          enthusiasts an opportunity to pursue entrepreneurship as a side
          hustle, providing them with an extra or secondary source of income.
        </div>
        <div className="counter_section">
          <ScrollTrigger
            onEnter={() => setCounterState(true)}
            onExit={() => setCounterState(false)}
          >
            <div className="counter_container">
              <div className="counter_content">
                <div className="count">
                  {counterState && (
                    <CountUp
                      start={0}
                      end={7.5}
                      duration={2.75}
                      separator=""
                      decimals={1}
                      decimal="."
                      suffix="Lac+"
                    ></CountUp>
                  )}
                </div>
                <div className="count_text">Momos Made</div>
              </div>
              <div className="counter_content">
                <div className="count">
                  {counterState && (
                    <CountUp
                      start={0}
                      end={50}
                      duration={2.75}
                      separator=""
                      suffix="K+"
                    ></CountUp>
                  )}
                </div>
                <div className="count_text">Customers Served</div>
              </div>
              <div className="counter_content">
                <div className="count">
                  {counterState && (
                    <CountUp
                      start={0}
                      end={30}
                      duration={2.75}
                      separator=""
                      suffix="Lac+"
                    ></CountUp>
                  )}
                </div>
                <div className="count_text">Revenue generated for partners</div>
              </div>
            </div>
          </ScrollTrigger>
        </div>

        <div className="card_section">
          <div className="card">
            <div className="card_icon">📊</div>
            <div className="card_text">Check Monthly Production Report</div>
          </div>
          <div className="card">
            <div className="card_icon">👍</div>
            <div className="card_text">Hear What Our Customers Say</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTheBrand;
