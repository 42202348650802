import React from 'react';
import "./FssaiRegistration.scss";
import img1 from "../../assets/images/zomato-img.png";
import img2 from "../../assets/images/swiggy-img.png";


const FssaiRegistration = () => {
  return (
    <div className='FssaiRegistration-section'>
      <div className="FssaiRegistration-container max-width">
      <div className="heading-section">
         <div className="centered-container">
      <div className="title-style">
        <span className="prefixTextBold">Assist in getting</span>{" "}
        <span className="middleTextBold">FSSAI</span>{" "}
        <span className="prefixTextBold">Registration</span>
        <span className="backgroundStyle"></span>
      </div>
    </div>
        <div className="title">
            Zomato & Swiggy <span>Onboarding</span>
        </div>
      </div>
      <div className="content-section">
        <div className="text">
        The Momos Mafia is now offering FSSAI <br />registration services and listing services on <br />Zomato and Swiggy. To provide these services, <br />we've partnered with agents across Delhi NCR <br />who assist us in delivering these services to you.
        </div>
        <div className="img-section">
        <img src={img1} alt='zomato' />
        <img src={img2} alt='swiggy' />
        </div>
      </div>
      </div>
    </div>
  )
}

export default FssaiRegistration
