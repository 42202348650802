import React from "react";
import "./Footer.scss";
import logo from "../../assets/images/momo_logo.svg";
import c_img from "../../assets/images/allrights-c-img.svg";
import whatsapp_icon from "../../assets/images/whatsapp-icon.svg";
import facebook_icon from "../../assets/images/facebook_icon.svg";
import insta_icon from "../../assets/images/insta_icon.svg";
import { useNavigate } from "react-router-dom";

export const CustomFooter = () => {
  const navigate = useNavigate();
  return (
    <div className="footer-section">
      <div className="footer-container max-width">
        <div className="top_side">
          <img className="logo" src={logo} alt="logo" />
        </div>
        <div className="middle_side">
        It all started with a vision to empower small and medium-sized food entrepreneurs, enabling them to kickstart their food business with minimal investment. Additionally, it aims to offer business enthusiasts an opportunity to pursue entrepreneurship as a side hustle, providing them with an extra or secondary source of income.
        </div>
        <div className="bottom_side">
          <div className="left">
            <img src={c_img} alt="c_img" />
            <p>TheMomosMafia 2024 | All rights reserved</p>
            <p onClick={() => navigate('/policy')} style={{cursor: "pointer"}}>Privacy Policy</p>
          </div>
          <div className="right">
          <img src={whatsapp_icon} alt="whatsapp_icon" />
          <img src={facebook_icon} alt="facebook_icon" />
          <img src={insta_icon} alt="insta_icon" />
          </div>
        </div>
      </div>
    </div>
  );
};
