import React from "react";
import "./TrainingSupport.scss";
import { Col, Row } from "antd";
import { data } from "./data";

const TrainingSupport = () => {
  return (
    <div className="training-section">
      <div className="training-container max-width">
         <div className="centered-container">
      <div className="title-style">
        <span className="prefixTextBold">Training &</span>{" "}
        <span className="middleTextBold">Support</span>{" "}
        <span className="backgroundStyle"></span>
      </div>
    </div>
        <div className="training-content">
          <Row gutter={[24,24]}>
            {data.map((item) => (
              <Col key={item.id} xs={12} xl={12}>
                <div className={`card card_${item.id}`}>
                    <div className="card_img">
                        <img src={item.img} alt="item_img" />
                    </div>
                    {item.text}
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default TrainingSupport;
