import React from 'react'
import "./RowMaterialDelivery.scss";
import coma from "../../assets/images/coma-yellow.webp";
import delivery_bike from "../../assets/images/Food Delivery.png";


const RowMaterialDelivery = () => {
  return (
    <div className='RowMaterialDelivery-section'>
        <div className="RowMaterialDelivery-container">
         <div className="centered-container">
      <div className="title-style">
        <span className="prefixTextBold">Raw Material Delivery</span>{" "}
        <span className="middleTextBold">at your doorstep</span>{" "}
        <span className="backgroundStyle"></span>
      </div>
    </div>
        <div className="text">
        Get the full competitive advantage of scale: Get <br />wholesale rates even when you order for just one cart
        </div>
        <div className="content-section">
        <div className="text-content">
          <div className="coma">
            <img src={coma} alt="coma" />
          </div>
          <div className="food-delivery-title">
            Momos Delivered through one of <br />the most{" "}
            <span>sophisticated supply <br />chains</span> in Delhi NCR
          </div>
          </div>
          <div className="food-delivery-img">
            <img src={delivery_bike} alt="delivery_bike" />
          </div>
          <div className="yellow-box">
          <span>Hub to Hub model</span> to keep lower logistic <br />cost for our partners 
          </div>
        </div>
        </div>
      
    </div>
  )
}

export default RowMaterialDelivery
