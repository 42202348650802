import React from "react";
import "./CapitalInvestment.scss";
import img1 from "../../assets/images/chest-freezer-1.png";
import img2 from "../../assets/images/chest-freezer-2.png";
import img3 from "../../assets/images/chest-freezer.png";
import { CustomButton as Button } from "../../CommonComponents/button/Button";

const CapitalInvestment = () => {
  return (
    <div className="CapitalInvestment-section">
      <div className="CapitalInvestment-container max-width">
        
        <div className="centered-container">
      <div className="title-style">
        <span className="prefixTextBold">Capital</span>{" "}
        <span className="middleTextBold">Investment</span>{" "}
        <span className="backgroundStyle"></span>
      </div>
    </div>
        <div className="text">
          The minimum investment required is outlined below. You can also opt<br />
          for additional add-ons based on your specific requirements. The<br />
          following items are included in the franchise package:
        </div>
        <div className="table-container">
          <table cellspacing="0">
            <thead>
              <tr>
                <td>Items</td>
                <td>Description</td>
                <td>Price</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cart</td>
                <td>
                  A portable cart that is easy to move and <br />
                  foldable, requiring minimal storage space.
                </td>
                <td>Rs. 45,600</td>
              </tr>
              <tr>
                <td>Tools & Equipments</td>
                <td>
                  24 types of required equipments to run <br />
                  the momos business is included{" "}
                </td>
                <td>Rs. 11,630</td>
              </tr>
              <tr>
                <td>Disposables</td>
                <td>
                  All types of Serving & Packaging Material <br />
                  to serve at least 150 person
                </td>
                <td>Rs. 2,300</td>
              </tr>
              <tr>
                <td>Staff T-Shirt & Apron</td>
                <td>
                  Branded Tshirts and aprons for your staff <br />
                  custom to their size
                </td>
                <td>Rs. 1,600</td>
              </tr>
              <tr>
                <td>Raw Materials</td>
                <td>
                  500+ momos, chutneys & spring roll to <br />
                  serve atleast 50 customers{" "}
                </td>
                <td>Rs. 3,870</td>
              </tr>
            </tbody>
          </table>
          <div className="package_cost">
            <div className="left_side">Franchise Package Cost</div>
            <div className="right_side">Rs. 65,000</div>
          </div>
        </div>
        <div className="optional-add-section">
          <div className="optional-title">
            Optional Add-Ons (based on your needs)
          </div>
          <div className="optional-text">
            In addition to the items listed in the franchise package, you can
            select add-ons based on your specific requirements. These are not
            included in the standard package as they vary depending on each
            partner's preferences.
          </div>
          <div className="optional-card-container">
            <div className="optional-card">
              <div className="top_side">
                {" "}
                <div className="card-img">
                  <img src={img1} alt="img" />
                </div> 
              </div>
             <div className="bottom">
             <div className="middle_side">
              <div className="card-title">Deep Freezer</div>
                <div className="card-price">Rs 10,000 - Rs. 18,000</div>
                <div className="ques-content">
                  <div className="question">Why do I need a Deep Freezer ?</div>
                  <div className="answer">
                    The entire supply chain is frozen, so each franchise owner
                    must manage a freezer.{" "}
                  </div>
                </div>
                <div className="ques-content">
                  <div className="question">From where can I get one ?</div>
                  <div className="answer">
                    If you're already in the food business, you can use your
                    existing freezer or purchase a second-hand one.
                  </div>
                </div>
                <div className="ques-content">
                  <div className="question">How much capacity do I need ?</div>
                  <div className="answer">
                    {" "}
                    A minimum of a 300-liter freezer is needed to store 7 days'
                    stocks.
                  </div>
                </div>
              </div>
              <div className="bottom_side">
                <div className="card_btn">
                  <div>🔊</div>
                  <div>List of suppliers</div>
                </div>
              </div>
             </div>
            </div>
            <div className="optional-card">
              <div className="top_side">
                {" "}
                <div className="card-img">
                  <img src={img2} alt="img" />
                </div> 
              </div>
             <div className="bottom">
             <div className="middle_side">
              <div className="card-title">Well Trained Staff</div>
                <div className="card-price">Rs 10,000</div>
                <div className="ques-content">
                  <div className="question">Why are you charging 10,000 ?</div>
                  <div className="answer">
                    We retain and train staff for 15-20 days. Additionally, we
                    provide replacements if your staff member leaves the counter
                    within 4 months.
                  </div>
                </div>
                <div className="ques-content">
                  <div className="question">
                    Can 1 staff take care of the cart ?
                  </div>
                  <div className="answer">
                    Initially, one staff member can manage operations until
                    sales reach 45-50 plates per day. Based on the sales volume
                    and counter traffic, you can opt to add an additional staff
                    member.
                  </div>
                </div>
              </div>
              <div className="bottom_side">
              <div className="card_btn">
                  <div>🔊</div>
                  <div>Training Module</div>
                </div>
              </div>
             </div>
            </div>
            <div className="optional-card">
              <div className="top_side">
                {" "}
                <div className="card-img">
                  <img src={img3} alt="img" />
                </div> 
              </div>
             <div className="bottom">
             <div className="middle_side">
             <div className="card-title">Tandoori Kit</div>
                <div className="card-price">Rs 15,000</div>
                <div className="ques-content">
                  <div className="question">When should I start Tandoori ?</div>
                  <div className="answer">
                    The tandoori setup is not included in the package because it
                    requires additional space and an extra staff member, which
                    can be costly initially. Once your business is established
                    and you're confident in expanding, adding a tandoori setup
                    will make more sense.
                  </div>
                </div>
              </div>
              <div className="bottom_side">
              <div className="card_btn">
                  <div>🔊</div>
                  <div>Explore Tandoori Kit</div>
                </div>
              </div>
             </div>
            </div>
            
           
          </div>
        </div>
        <div className="button-section">
          <Button
            title="Apply For Franchise"
            customBtnClass="primaryBtn"
            
          />
          <Button
            title="Book a Meeting"
            customBtnClass="SecondaryBtn"
            
          />
        </div>
      </div>
    </div>
  );
};

export default CapitalInvestment;
