import React, { useState } from "react";
import { Button, Col } from "antd";
import arrow_1 from "../../assets/images/arrow_1.svg";
import { NavLink } from "react-router-dom";

const FranchiseOverViewCard = ({ item }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <Col xs={12} sm={8} xl={8} key={item.id}>
      <div
        className={`card card_${item.id}`}
        style={{
          backgroundImage: `url(${item.img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="content">
          <div className="left-side">{item.text}</div>
          <div className="right-side">
            <NavLink to={``}>
              <Button
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                className="arrow-btn"
              >
                <img src={isHovered ? arrow_1 : arrow_1} />
              </Button>
            </NavLink>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default FranchiseOverViewCard;
