import React from 'react'

const LocationCardNavigationItem = ({ label, span, onClick, isActive }) => {
  return (
    <div className={isActive ? 'active-cart-title cart-title' : 'cart-title'} onClick={onClick}>
          {label} {span}
        </div>
  )
}

export default LocationCardNavigationItem
