import React from "react";
import "./Policy.scss";

const Policy = () => {
  return (
    <div className="policy-section">
      <div className="policy-container max-width">
        <section className="agreement-container">
          <h1>TMM Franchise Agreement</h1>
          <h2>Table of Content</h2>
          <ul>
            <li>1. Franchise Package Detail</li>
            <li>
              2. Pricing Model for The Momos Mafia (TMM) Franchise Package
            </li>
            <li>3. Cart Ownership and Specification Agreement</li>
            <li>4. Branding and Disposable Usage Agreement</li>
            <li>5. Staff Hiring, Training and Accommodation</li>
            <li>6. Location</li>
            <li>7. Cart parking</li>
            <li>8. Cart Maintenance</li>
            <li>9. Day to day Operations</li>
            <li>10. Raw Material Logistics</li>
            <li>11. Customization Policy</li>
            <li>12. Franchise Cost & Royalty</li>
            <li>13. Business Ownership Clarification</li>
            <li>14. Investment Overview</li>
            <li>15. Menu & Product Pricing Framework</li>
            <li>16. Quality Standards Collaboration & Penalty Framework</li>
            <li>17. Centralised Marketing Advantage</li>
            <li>18. Cart Maintenance Assurance for Your Peace of Mind</li>
            <li>19. Efficient Waste Management</li>
            <li>20. Leveraging Technology for Success</li>
            <li>21. Package Upgrades</li>
            <li>22. Exclusive Territory Protection</li>
            <li>23. GST & Billing Policy at The Momos Mafia (TMM)</li>
          </ul>
        </section>
        <section className="agreement-section">
          <div className="heading-section">
            <div className="heading">
              Cart Ownership and Specification Agreement
            </div>
            <div className="text">
              {" "}
              This document outlines the terms and conditions regarding the
              ownership of the momo cart provided by The Momos Mafia (TMM) under
              the Cart Ownership and Specification Agreement.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title"> Cart Ownership:</div>
            <div className="text">
              {" "}
              Once the full payment for the cart is received, ownership of the
              cart is transferred to the franchisee, and it becomes the
              partner's property.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title"> Cart Inspection:</div>
            <div className="text">
              TMM reserves the right to inspect the momo cart periodically to
              ensure it meets the specified standards and is well-maintained.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title"> Cart Maintenance:</div>
            <div className="text">
              {" "}
              The franchisee is responsible for the day-to-day maintenance of
              the cart as per the provided Cart Maintenance Checklist. Any costs
              associated with breakages or mishandling during this period are
              the responsibility of the franchisee
            </div>
          </div>
          <div className="text">
            {" "}
            This agreement aims to establish clear guidelines for cart ownership
            and specifications, promoting a standardised and high-quality
            representation of The Momos Mafia brand. Franchisees are encouraged
            to familiarise themselves with these terms and uphold the
            agreed-upon standards for the successful operation of their momo
            cart business
          </div>
        </section>
        <section className="agreement-section">
          <div className="heading-section">
            <div className="heading">
              Branding and Disposable Usage Agreement
            </div>
            <div className="text">
              This document outlines the branding and disposable usage terms
              between The Momos Mafia (TMM) and the franchisee under the
              Branding and Disposable Usage Agreement.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Branding:</div>
            <div className="text">
              All branding associated with the momo cart, including logos,
              signage, and promotional materials, will be exclusively that of
              The Momos Mafia. The franchisee is prohibited from making any
              changes to the branding without prior written consent from TMM.
              Branding consistency is crucial for maintaining the integrity of
              the TMM brand.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Disposable Usage:</div>
            <div className="text">
              All disposables used in the momo cart business, such as packaging,
              napkins, and containers, must bear the branding of The Momos
              Mafia. The franchisee is strictly prohibited from using
              disposables from any other source or brand. Uniform branding
              enhances the overall brand image and customer experience.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Brand Recognition:</div>
            <div className="text">
              Consistent branding across all TMM franchises contributes to
              better brand recognition and customer loyalty. Franchisees are
              expected to uphold the established branding standards to align
              with TMM's overall marketing strategy.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Brand Compliance:</div>
            <div className="text">
              Any changes or deviations from the prescribed branding standards
              must be approved by TMM to ensure compliance with brand
              guidelines. Franchisees are encouraged to contact TMM for guidance
              on maintaining brand consistency.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Disposable Supply:</div>
            <div className="text">
              TMM will supply the franchisee with the required disposables,
              ensuring uniformity and adherence to brand standards. Franchisees
              are responsible for ordering disposables through TMM to maintain
              consistent branding.
            </div>
          </div>
          <div className="text">
            This agreement establishes the importance of consistent branding and
            disposable usage for The Momos Mafia franchises. It serves to create
            a unified brand identity, contributing to a cohesive and
            recognizable presence in the market. Franchisees are expected to
            comply with these terms to strengthen the brand and foster a
            positive customer experience.
          </div>
        </section>
        <section className="agreement-section">
          <div className="heading-section">
            <div className="heading">
              Staff Hiring, Training and Accommodation
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">
              Staff Hiring and Training Responsibility:
            </div>
            <div className="text">
              The Momos Mafia (TMM) takes charge of the recruitment and training
              processes for the franchise outlet's staff. This includes
              identifying suitable candidates, conducting interviews, and
              providing necessary training programs to ensure that all staff
              members meet TMM's standards.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Accommodation Requirement:</div>
            <div className="text">
              The franchisee is responsible for arranging suitable accommodation
              for the staff, adhering to the guidelines specified in the
              accommodation checklist provided by TMM.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Training Support:</div>
            <div className="text">
              TMM commits to delivering the essential training programs required
              for the franchise outlet. This training ensures that staff members
              are equipped with the necessary skills and knowledge to uphold
              TMM's operational standards.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Reporting Structure:</div>
            <div className="text">
              Staff members are accountable to both the franchisee and TMM. The
              franchisee oversees the day-to-day reporting and management of the
              staff. Additionally, TMM retains the right to communicate directly
              with the staff when deemed necessary.
            </div>
          </div>
          <div className="text">
            These provisions establish a collaborative approach to staffing,
            aiming to maintain consistency and quality in service across all TMM
            franchise outlets.
          </div>
        </section>
        <section className="agreement-section">
          <div className="heading-section">
            <div className="heading">Location</div>
            <div className="text">
              The section regarding Cart Placement and Location Operations in
              the franchise agreement includes the following terms:
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Cart Placement Responsibility:</div>
            <div className="text">
              The franchisee is responsible for suggesting a proposed location
              for the cart. However, TMM retains the authority to reject the
              proposed location if it fails to meet the criteria outlined in the
              successful location shortlisting checklist.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Day-to-Day Location Operations:</div>
            <div className="text">
              Once the cart is placed, the franchisee assumes responsibility for
              all daily operations related to the location. This encompasses
              tasks such as managing rent payments, liaising with local
              authorities, and adhering to regulations imposed by entities like
              the Municipal Corporation (MCD).
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Support from TMM:</div>
            <div className="text">
              While the franchisee handles the day-to-day location operations,
              TMM pledges to provide comprehensive support to the franchisee in
              its full capacity. This support may include guidance, advice, and
              assistance in dealing with location-related challenges.
            </div>
          </div>
          <div className="text">
            By delineating these responsibilities, the franchise agreement aims
            to establish a clear framework for both the franchisee and TMM to
            contribute to the successful and compliant operation of the cart in
            its chosen location.
          </div>
        </section>
        <section className="agreement-section">
          <div className="heading-section">
            <div className="heading">Cart Parking</div>
            <div className="text">
              The section addressing Cart Parking in the franchise agreement
              includes the following stipulations:
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Proximity Requirement:</div>
            <div className="text">
              The franchisee is obligated to ensure that the designated parking
              area for the cart is in close proximity to the location where the
              cart is operated. Specifically, there should not be a gap of more
              than 500 metres between the running location and the designated
              parking space.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Overnight Storage Consideration:</div>
            <div className="text">
              The agreement suggests that the franchisee actively seeks a
              parking location that allows the cart to be securely locked and
              stored overnight. This recommendation emphasises the importance of
              securing the cart during non-operational hours to prevent theft or
              damage.
            </div>
          </div>
          <div className="text">
            By incorporating these provisions, the franchise agreement aims to
            establish guidelines for the franchisee to adhere to when selecting
            and managing the parking arrangement for the cart. The goal is to
            ensure operational convenience and safeguard the cart during periods
            of inactivity.
          </div>
        </section>
        <section className="agreement-section">
          <div className="heading-section">
            <div className="heading">Cart Maintenance</div>
            <div className="text">
              This clause in the franchise agreement outlines the
              responsibilities related to Cart Maintenance:
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Maintenance Obligation:</div>
            <div className="text">
              During the period when the cart is still owned by TMM, the
              franchisee is obligated to adhere to a Cart Maintenance Checklist.
              This checklist likely includes guidelines for regular cleaning,
              upkeep, and minor repairs to ensure the cart's optimal condition.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Cost Responsibility:</div>
            <div className="text">
              Any costs arising from breakages or mishandling of the cart during
              this period are the sole responsibility of the franchisee. This
              includes expenses related to repairs or replacements necessitated
              by damages.
            </div>
          </div>
          <div className="text">
            By incorporating these terms, the franchise agreement aims to
            establish a clear framework for the maintenance of the cart,
            specifying the duties of the franchisee and addressing the financial
            responsibility for any damages incurred during this period.
          </div>
        </section>
        <section className="agreement-section">
          <div className="heading-section">
            <div className="heading">Day-to-Day Operations</div>
            <div className="text">
              This section of the franchise agreement outlines the day-to-day
              operational responsibilities of the franchisee:
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Cart Operation:</div>
            <div className="text">
              The franchisee is responsible for the daily operation of the cart.
              This includes tasks such as opening the cart for business,
              ensuring it is operational, and managing the daily workflow.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Cleaning and Maintenance:</div>
            <div className="text">
              Regular cleaning and maintenance of the cart fall under the
              franchisee's duties. This involves keeping the cart in a clean and
              hygienic condition to uphold brand standards.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Customer Handling:</div>
            <div className="text">
              Interacting with customers and providing service aligning with The
              Momos Mafia's (TMM) standards is a crucial role for the
              franchisee. This includes ensuring a positive customer experience
              and addressing any customer inquiries or concerns.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Raw Material Procurement:</div>
            <div className="text">
              The franchisee is responsible for sourcing and ordering the
              necessary raw materials for preparing momos. This involves
              maintaining an adequate inventory to meet customer demand while
              adhering to TMM's quality standards.
            </div>
          </div>
          <div className="text">
            By allocating these responsibilities to the franchisee, the
            agreement establishes a framework for the day-to-day operations,
            emphasising the hands-on involvement required to successfully run
            the momo cart business.
          </div>
        </section>
        <section className="agreement-section">
          <div className="heading-section">
            <div className="heading">Raw Material Logistics</div>
            <div className="text">
              TMM takes charge of transporting raw materials to your location.
              The franchisee is responsible for the associated logistics
              charges, payable promptly after each delivery.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Supply of Raw Materials:</div>
            <div className="text">
              Under this agreement, TMM takes the responsibility of providing
              all necessary raw materials required for the preparation of momos.
              These materials, including but not limited to momos, chutneys, and
              masalas, are supplied at the rates specified in the Rate List
              provided by TMM. The franchisee benefits from the convenience of a
              one-stop-shop for all their raw material needs.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Discounts on Bulk Orders:</div>
            <div className="text">
              To encourage efficient business operations and cost savings for
              the franchisee, TMM offers discounts on bulk orders. This allows
              franchisees to manage their inventory effectively and capitalise
              on economies of scale, contributing to overall operational
              efficiency.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Exclusive Vendor Agreement:</div>
            <div className="text">
              Franchisees are bound by an exclusive vendor agreement,
              prohibiting them from sourcing raw materials from any supplier
              other than TMM. This exclusivity ensures uniformity in product
              quality and adherence to standardised recipes, essential for
              maintaining the brand's identity and reputation.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Penalty Clause:</div>
            <div className="text">
              To maintain the integrity of the brand and uphold quality
              standards, TMM has implemented a Penalty Clause. If a franchisee
              is found in violation of the exclusive vendor agreement by selling
              items not provided by TMM, they will be subject to penalties, the
              details of which are outlined in the agreement. This provision
              acts as a deterrent against any deviation from the agreed-upon
              terms.
            </div>
          </div>
          <div className="text">
            In summary, TMM's approach to the supply of raw materials is
            comprehensive, offering convenience, cost-effectiveness, and a
            commitment to maintaining the brand's quality standards.
          </div>
        </section>
        <section className="agreement-section">
          <div className="heading-section">
            <div className="heading">Customization Policy</div>
            <div className="text">
              As part of its commitment to catering to diverse preferences, The
              Momos Mafia (TMM) allows franchisees to submit customization
              requests for momo items. However, there are specific guidelines
              and conditions associated with this process:
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Submission of Customization Requests:</div>
            <div className="text">
              Franchisees are welcome to submit requests for customized momo
              items. These requests should be communicated to TMM through the
              designated channel provided in the franchise agreement.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Research and Development Process:</div>
            <div className="text">
              Upon receiving a customization request, TMM initiates a thorough
              research and development process. This involves assessing the
              feasibility, market demand, and potential impact on existing
              product offerings. TMM is dedicated to creating unique and
              innovative momo items that align with its brand identity.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Approval or Rejection of Customization:</div>
            <div className="text">
              TMM holds the full authority to either approve or reject a
              customization request. The decision is based on a set of
              predefined product innovation criteria. If the proposed
              customization aligns with TMM's standards and market trends, it is
              likely to be approved. However, TMM reserves the right to reject
              requests that do not meet these criteria.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Criteria for Product Innovation:</div>
            <div className="text">
              The product innovation criteria include considerations such as
              taste, quality, uniqueness, and compatibility with TMM's overall
              brand image. Customizations that enhance the menu diversity and
              appeal to the target market are typically favored.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Transparent Communication:</div>
            <div className="text">
              Throughout this process, TMM maintains transparent communication
              with franchisees, keeping them informed about the status of their
              customization requests. Clear and timely communication ensures a
              collaborative and constructive approach to product development.
            </div>
          </div>
          <div className="text">
            In summary, TMM encourages customization but adheres to a strategic
            and criteria-based approach to ensure that any additions to the menu
            align with the brand's standards and contribute positively to the
            overall customer experience.
          </div>
        </section>
        <section className="agreement-section">
          <div className="heading-section">
            <div className="heading">Franchise Cost & Royalty</div>
            <div className="text">
              As part of its commitment to fostering entrepreneurial
              opportunities, The Momos Mafia (TMM) has adopted a transparent and
              supportive approach regarding franchise costs and royalties. Here
              are the key aspects of this policy:
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Franchise Cost:</div>
            <div className="text">
              TMM does not impose any upfront franchise charges on individuals
              or entities interested in becoming franchise partners. This means
              that there are no initial fees or costs associated with obtaining
              a momo cart franchise from TMM. The absence of franchise costs
              reflects TMM's dedication to minimizing financial barriers for
              aspiring entrepreneurs.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Royalty on Sales or Profit:</div>
            <div className="text">
              TMM also distinguishes itself by not imposing any royalties on
              sales or profits generated by its franchise partners. Franchisees
              retain the entirety of their earnings without any percentage-based
              deductions. This approach is designed to provide franchisees with
              the maximum financial benefit from their momo cart business.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Financial Empowerment of Franchisees:</div>
            <div className="text">
              By removing franchise costs and royalties, TMM aims to empower its
              franchisees to thrive and succeed in their momo cart ventures.
              This model aligns with TMM's philosophy of creating mutually
              beneficial partnerships, where both the franchisor and franchisee
              can prosper without burdensome financial obligations.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Sustainable Business Model:</div>
            <div className="text">
              TMM's decision to eliminate franchise costs and royalties
              contributes to the creation of a sustainable and equitable
              business model. Franchisees can focus on building and growing
              their momo cart businesses without the constraints of traditional
              fee structures.
            </div>
          </div>
          <div className="text">
            In summary, TMM's franchise cost and royalty policy reflects a
            commitment to financial inclusivity and a belief in the potential of
            its franchise partners. This approach allows entrepreneurs to embark
            on their momo cart business journeys with greater financial
            flexibility and the prospect of maximizing their returns.
          </div>
        </section>
        <section className="agreement-section business-ownership-section">
          <div className="heading-section">
            <div className="heading">Business Ownership</div>
            <div className="text">
              The Momos Mafia (TMM) firmly upholds the principle that
              franchisees are the owners of their momocart businesses. This
              delineation of business ownership is crucial for fostering a sense
              of autonomy and accountability among franchise partners. Here's an
              elucidation of the business ownership dynamics:
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Franchisee Ownership:</div>
            <div className="text">
              Upon entering into a momo cart franchise agreement with TMM,
              franchisees assume full ownership and control of their respective
              businesses. TMM recognizes and respects the entrepreneurial spirit
              and effort that franchisees invest in establishing and operating
              their momocarts.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Profit and Loss Autonomy:</div>
            <div className="text">
              Crucially, TMM does not assert any rights over the profits or
              losses incurred by franchisees. Franchisees have complete autonomy
              in managing the financial aspects of their businesses. Profits
              generated from momo cart sales belong entirely to the franchisee,
              and any associated losses are borne by the franchisee without
              external interference.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Entrepreneurial Empowerment:</div>
            <div className="text">
              By affirming the franchisee's role as the sole proprietor of their
              momo cart business, TMM aims to empower entrepreneurs to make
              independent decisions that align with their business goals. This
              empowerment extends to all aspects of business operations,
              including strategic planning, marketing, and financial management.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Collaborative Partnership:</div>
            <div className="text">
              While TMM provides support, guidance, and access to the
              established momo cart brand, it does not impose constraints on the
              entrepreneurial freedom of franchisees. The relationship is
              characterised by collaboration, where both TMM and franchisees
              work together to achieve success, but the ultimate responsibility
              and ownership lie with the franchisee.
            </div>
          </div>

          <div className="text">
            In essence, TMM's stance on business ownership underscores a
            commitment to fostering an environment where franchisees can
            flourish as independent business owners. This approach encourages
            innovation, initiative, and a strong sense of ownership,
            contributing to the overall success and sustainability of momo cart
            businesses within the TMM franchise network.
          </div>
        </section>
        <section className="agreement-section investment-overview-section">
          <div className="heading-section">
            <div className="heading">Investment Overview</div>
            <div className="text">
              In the collaborative venture between The Momos Mafia (TMM) and
              franchisees, the responsibility for financial investment lies
              squarely with the franchisee. Here's a detailed overview of the
              investment dynamics:
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Franchisee-Led Investment:</div>
            <div className="text">
              The franchisee is the primary stakeholder in terms of financial
              investment. All capital required for establishing and operating
              the momo cart business, including but not limited to franchise
              package costs, daily supplies, and operational expenses, is the
              financial commitment of the franchisee.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Financial Autonomy:</div>
            <div className="text">
              TMM recognizes the importance of financial autonomy for
              franchisees. This approach grants franchisees the freedom to make
              strategic decisions regarding their investment, such as choosing
              between one-time payments or installment options, based on their
              financial preferences and capabilities.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Transparent Financial Arrangement:</div>
            <div className="text">
              The financial relationship between TMM and the franchisee is
              transparent and straightforward. TMM does not levy additional
              charges, royalties, or ongoing fees based on the sales or profits
              of the franchisee's business. This model ensures that the
              financial arrangement is clear, promoting a fair and equitable
              partnership.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Ownership of Investment Decisions:</div>
            <div className="text">
              The franchisee's investment extends beyond mere financial
              transactions; it represents a commitment to building and nurturing
              a successful momo cart business. The franchisee exercises
              ownership not only in financial matters but also in strategic
              investment decisions, contributing to the overall success and
              growth of their business.
            </div>
          </div>

          <div className="text">
            In summary, TMM's investment framework places the franchisee at the
            forefront of financial decisions, emphasizing autonomy,
            transparency, and flexibility. This approach aims to create an
            environment where franchisees can strategically invest in and shape
            their momo cart businesses to achieve long-term success.
          </div>
        </section>
        <section className="agreement-section menu-pricing-framework-section">
          <div className="heading-section">
            <div className="heading">Menu & Product Pricing Framework</div>
            <div className="text">
              In the collaborative culinary journey between The Momos Mafia
              (TMM) and franchisees, the approach towards menu composition and
              product pricing is meticulously structured. Here's a detailed
              breakdown of the menu and pricing dynamics:
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Centralised Decision-Making:</div>
            <div className="text">
              The pricing strategy for menu items is centrally determined by
              TMM. This centralised approach ensures consistency and uniformity
              in product pricing across all momo cart locations. The
              decision-making process is methodically crafted to align with
              TMM's brand standards and market considerations.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Geographic Pricing Variability:</div>
            <div className="text">
              Recognizing the diverse economic landscapes across different
              geographic locations, TMM retains the authority to implement
              regional variations in product pricing. This flexibility allows
              TMM to adapt to local market conditions while maintaining a
              cohesive brand identity.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Exclusive Product Offering:</div>
            <div className="text">
              Franchisees are obligated to sell only the products made available
              by TMM. This exclusivity safeguards the brand integrity and
              ensures a standardized momo experience for customers across all
              TMM momo cart locations.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Franchisee Input and Collaboration:</div>
            <div className="text">
              While TMM has the primary authority in determining menu items and
              prices, franchisees are encouraged to provide suggestions. TMM
              values the collaborative spirit and is open to discussions with
              franchisees regarding potential additions or modifications to the
              menu. This approach fosters a cooperative environment where
              franchisees can actively contribute to the menu evolution.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Strategic Decision-Making:</div>
            <div className="text">
              Franchisees play a vital role in shaping the local culinary scene
              by providing insights into regional preferences. TMM's
              receptiveness to franchisee input allows for strategic
              decision-making that resonates with specific customer demographics
              and preferences in different locations.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">
              Open Dialogue for Continuous Improvement:
            </div>
            <div className="text">
              The menu and pricing framework is not static; it is subject to
              continuous evaluation and improvement. TMM and franchisees engage
              in an ongoing dialogue to assess market dynamics, customer
              feedback, and emerging trends, ensuring that the menu remains
              dynamic and responsive to changing tastes.
            </div>
          </div>

          <div className="text">
            In essence, the menu and pricing framework emphasizes a balanced
            approach where TMM's centralised strategy meets franchisees'
            collaborative input. This synergy aims to create a diverse yet
            cohesive menu offering that resonates with customers while
            maintaining the brand's distinctive identity.
          </div>
        </section>
        <section className="agreement-section quality-standards-section">
          <div className="heading-section">
            <div className="heading">
              Quality Standards Collaboration & Penalty Framework
            </div>
            <div className="text">
              Maintaining impeccable quality is a joint commitment between The
              Momos Mafia (TMM) and franchisees. TMM assumes the responsibility
              for ensuring the quality of products up to the point of delivery
              to the franchisee. During this phase, TMM adheres rigorously to
              established quality benchmarks, upholding its commitment to
              delivering top-tier momos.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Franchisee Custodianship:</div>
            <div className="text">
              Upon delivery, the mantle of responsibility transitions to the
              franchisee. The franchisee is entrusted with the crucial task of
              upholding product quality in accordance with the Brand & Product
              Quality Standards Rulebook. This rulebook serves as a
              comprehensive guide, delineating the standards that define the
              excellence associated with TMM momos.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Rulebook Adherence & Penalty Clause:</div>
            <div className="text">
              Franchisees are obligated to adhere strictly to the stipulations
              outlined in the Brand & Product Quality Standards Rulebook. Any
              deviation from or failure to meet these standards will result in
              penalties, as specified in the Penalty Clause. This clause serves
              as a deterrent, underscoring the significance of maintaining
              quality at all times.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Penalty Framework:</div>
            <div className="text">
              The Penalty Clause encompasses a structured framework for
              addressing instances where quality standards are compromised.
              Penalties are levied based on the severity of the deviation and
              are designed to ensure corrective measures are promptly
              implemented.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Continuous Monitoring & Feedback:</div>
            <div className="text">
              To facilitate adherence, continuous monitoring mechanisms are in
              place. Both TMM and franchisees engage in a feedback loop,
              fostering open communication to address challenges and implement
              improvements. This collaborative approach aims to create a dynamic
              quality assurance process that adapts to evolving circumstances.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Training Initiatives:</div>
            <div className="text">
              TMM conducts regular training initiatives to empower franchisees
              with the knowledge and skills needed for effective quality
              control. These sessions foster a shared understanding of quality
              standards across the entire TMM momo cart network.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Quality Audits:</div>
            <div className="text">
              Periodic quality audits are conducted by TMM to assess adherence
              to quality standards. These audits serve as proactive measures,
              identifying areas for improvement and recognizing exemplary
              adherence to quality benchmarks.
            </div>
          </div>

          <div className="text">
            In summary, the Quality Standards Collaboration & Penalty Framework
            establishes a robust system that incentivizes adherence to TMM's
            high-quality standards. This shared commitment ensures that every
            momo consistently reflects the excellence synonymous with The Momos
            Mafia brand.
          </div>
        </section>
        <section className="agreement-section centralised-marketing-section">
          <div className="heading-section">
            <div className="heading">Centralised Marketing Advantage</div>
            <div className="text">
              At The Momos Mafia (TMM), we recognize the pivotal role that
              marketing plays in propelling our brand and elevating the success
              of our franchise partners. Our commitment to driving business
              growth extends beyond individual outlets to a collective,
              synergistic effort that benefits all franchise partners.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Strategic Campaigns:</div>
            <div className="text">
              TMM takes the lead in crafting and executing strategic marketing
              campaigns that resonate with our target audience. These campaigns
              are designed to amplify brand visibility and create a compelling
              narrative around TMM momos.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Multi-Channel Presence:</div>
            <div className="text">
              Leveraging a diverse range of marketing channels, TMM ensures a
              widespread and impactful presence. From digital platforms to
              traditional channels, our marketing initiatives are meticulously
              planned to maximize reach and engagement.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Brand Consistency:</div>
            <div className="text">
              Centralised marketing efforts are rooted in maintaining consistent
              branding across all touchpoints. This approach not only reinforces
              brand recognition but also cultivates a unified brand image that
              consumers can trust.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Collaborative Promotions:</div>
            <div className="text">
              Franchise partners benefit from collaborative promotions initiated
              by TMM. These promotions are strategically aligned with broader
              marketing campaigns, creating a synchronised and impactful
              promotional calendar.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Localised Adaptations:</div>
            <div className="text">
              While centralization ensures a cohesive brand message, TMM
              recognizes the importance of localised adaptations. Marketing
              strategies are fine-tuned to cater to regional nuances, ensuring
              relevance and resonance within diverse markets.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Market Research Insights:</div>
            <div className="text">
              TMM invests in comprehensive market research to gain valuable
              insights into consumer behavior, preferences, and emerging trends.
              This data-driven approach informs our marketing strategies,
              empowering franchise partners with a competitive edge.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Digital Dominance:</div>
            <div className="text">
              In an era dominated by digital interactions, TMM places
              significant emphasis on digital marketing. From social media
              campaigns to online promotions, our digital initiatives are
              designed to captivate and engage a wide-ranging audience.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Shared Success:</div>
            <div className="text">
              The success of centralised marketing translates into shared
              success for all franchise partners. As brand visibility grows,
              footfall increases, benefiting each outlet within the TMM momocart
              network.
            </div>
          </div>

          <div className="text">
            By centralising our marketing efforts, TMM strives to create a
            thriving ecosystem where each franchise partner enjoys the
            advantages of a robust, well-coordinated marketing strategy. This
            collaborative approach exemplifies our commitment to fostering
            sustainable growth and success across the entire TMM franchise
            network.
          </div>
        </section>
        <section className="agreement-section cart-maintenance-section">
          <div className="heading-section">
            <div className="heading">
              Cart Maintenance Assurance for Your Peace of Mind
            </div>
            <div className="text">
              At The Momos Mafia, we understand the significance of a
              well-maintained and fully functional momo cart in ensuring a
              seamless business operation for our valued franchise partners.
              While we don't offer a formal warranty on the cart, we provide a
              reliable repair service to address any unforeseen damages that may
              occur during its usage.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">No Formal Warranty:</div>
            <div className="text">
              We don't provide a standard warranty on the momo cart. However,
              this doesn't compromise the commitment to the quality and
              durability of our carts, crafted to withstand the rigours of daily
              operations.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Responsive Repair Service:</div>
            <div className="text">
              In the event of any damage to the cart, our dedicated repair
              service comes into play. This service is aimed at swiftly
              addressing issues and restoring the cart to optimal working
              condition, minimising downtime for your business.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Franchisee Responsibility:</div>
            <div className="text">
              The cost associated with repairing the momo cart is the
              responsibility of the franchisee. We believe in transparent
              communication, and any expenses related to repairs will be borne
              by the respective franchise partner.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Service Availability (Delhi NCR):</div>
            <div className="text">
              Our repair service is currently available in the Delhi National
              Capital Region (NCR). For franchisees operating in this region, we
              ensure prompt and efficient assistance for cart repairs.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Proactive Maintenance:</div>
            <div className="text">
              While repairs are addressed reactively, we also emphasise
              proactive maintenance. Regular upkeep and adherence to our
              maintenance checklist can significantly contribute to the
              longevity and reliability of the momo cart.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Continuous Support:</div>
            <div className="text">
              TMM remains committed to providing continuous support to our
              franchise partners. Whether it's routine maintenance guidance or
              addressing unexpected issues, our support system is designed to
              keep your momo cart running smoothly.
            </div>
          </div>

          <div className="text">
            At The Momos Mafia, we prioritise the success and satisfaction of
            our franchise partners. The cart repair service underscores our
            dedication to ensuring that your momo cart remains in optimal
            condition, contributing to the overall efficiency and profitability
            of your momo business.
          </div>
        </section>
        <section className="agreement-section waste-management-section">
          <div className="heading-section">
            <div className="heading">Efficient Waste Management</div>
            <div className="text">
              At The Momos Mafia (TMM), we believe in fostering responsible and
              efficient business practices. As part of our commitment to
              sustainability, we emphasise the importance of controlling wastage
              at the franchisee level. Here are the key aspects related to
              wastage management:
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Franchisee Responsibility:</div>
            <div className="text">
              Once the TMM products reach the franchisee partner, the
              responsibility for managing and minimising wastage lies with the
              franchisee. This includes implementing effective inventory
              control, monitoring product shelf life, and adopting measures to
              prevent unnecessary wastage.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">No Cost Borne by TMM:</div>
            <div className="text">
              It's essential to note that TMM will not bear any costs associated
              with wastage incurred at the franchisee level. As the products are
              now in the possession of the franchisee, they play a pivotal role
              in ensuring that wastage is kept to a minimum.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Efficient Inventory Practices:</div>
            <div className="text">
              Franchisees are encouraged to implement efficient inventory
              practices to avoid overstocking, expiry of products, or any other
              factors that contribute to wastage. Regular checks on inventory
              levels and rotation of stock can significantly contribute to waste
              reduction.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Sustainable Operations:</div>
            <div className="text">
              TMM supports sustainable business operations, and minimising
              wastage aligns with our shared commitment to environmental
              responsibility. Franchisees are encouraged to explore ways to
              repurpose or donate excess food items whenever feasible.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Wastage Control Measures:</div>
            <div className="text">
              Franchisees can adopt various measures to control wastage, such as
              optimising order quantities based on demand, implementing
              effective storage practices, and managing preparation processes to
              match customer flow.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Transparent Communication:</div>
            <div className="text">
              Clear communication between TMM and franchisees regarding
              inventory levels, product shelf life, and any challenges related
              to wastage is crucial. This transparency enables collaborative
              efforts to find solutions and continuously improve operational
              efficiency.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Continual Improvement:</div>
            <div className="text">
              TMM is committed to working collaboratively with franchisees to
              identify opportunities for continual improvement in waste
              management practices. Regular assessments and feedback contribute
              to evolving strategies that benefit both parties.
            </div>
          </div>

          <div className="text">
            By embracing responsible business practices, including effective
            waste management, franchisees contribute to the overall success and
            sustainability of their momo business with TMM.
          </div>
        </section>
        <section className="agreement-section tech-support-section">
          <div className="heading-section">
            <div className="heading">Leveraging Technology for Success</div>
            <div className="text">
              At The Momos Mafia (TMM), we are dedicated to providing our
              franchise partners with the tools and support needed for efficient
              operations. Our commitment extends to leveraging technology to
              streamline communication and enhance various aspects of business
              management. Here's a comprehensive overview of the tech support
              provided to our valued franchisees:
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Dedicated Franchisee App:</div>
            <div className="text">
              TMM offers a user-friendly and intuitive mobile application
              designed exclusively for franchisees. This app serves as a central
              hub for all communication and interactions between the franchisee
              and TMM. With easy navigation, it facilitates seamless operations.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Ordering Convenience:</div>
            <div className="text">
              Franchisees can conveniently place product orders through the app,
              ensuring a swift and hassle-free process. This feature enables
              efficient inventory management and helps maintain optimal stock
              levels to meet customer demand.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Accounting Integration:</div>
            <div className="text">
              Our Franchisee App integrates robust accounting features,
              providing real-time insights into financial transactions.
              Franchisees can access billing details, track payments, and
              maintain financial records effortlessly, contributing to
              transparent and well-managed accounting practices.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Analytics for Informed Decisions:</div>
            <div className="text">
              TMM understands the importance of data-driven decision-making. The
              app includes analytics tools that offer valuable insights into
              sales trends, customer preferences, and overall business
              performance. Franchisees can leverage this information to make
              informed strategic decisions.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Efficient Communication Channel:</div>
            <div className="text">
              The app serves as an efficient communication channel between TMM
              and franchisees. Important announcements, updates, and relevant
              information are communicated promptly through the app, fostering
              clear and transparent communication.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Training Resources:</div>
            <div className="text">
              As part of our tech support, the app provides access to training
              resources. Franchisees can find training modules, guides, and
              materials that contribute to staff development, ensuring
              consistent service quality.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">User Support and Assistance:</div>
            <div className="text">
              Our tech support team is readily available to assist franchisees
              with any app-related queries or concerns. Whether it's navigating
              the features or troubleshooting, we are committed to providing
              ongoing support to ensure a smooth experience.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Continuous Updates and Enhancements:</div>
            <div className="text">
              TMM is dedicated to the continual improvement of our technology
              solutions. Regular updates and enhancements to the Franchisee App
              are rolled out to incorporate valuable feedback, address any
              issues, and introduce new features for enhanced functionality.
            </div>
          </div>

          <div className="text">
            By embracing technology, TMM aims to empower franchisees with the
            tools they need to thrive in the competitive momo business
            landscape. We believe that efficient operations contribute to the
            overall success and growth of our valued franchise partners.
          </div>
        </section>
        <section className="agreement-section package-upgrades-section">
          <div className="heading-section">
            <div className="heading">Package Upgrades</div>
            <div className="text">
              At The Momos Mafia (TMM), transparency and collaboration are at
              the core of our relationship with franchisees. To ensure that our
              partnership evolves with shared understanding and agreement, we
              have established a clear process for package upgrades. Here's an
              overview of how this process works:
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Communication:</div>
            <div className="text">
              Before implementing any package upgrades, TMM will communicate the
              proposed changes to the franchisee. This communication will
              include detailed information about the nature of the upgrades, the
              benefits they bring, and any associated changes in terms or costs.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Discussion and Agreement:</div>
            <div className="text">
              TMM encourages open communication and values the input of our
              franchise partners. Franchisees will have the opportunity to
              discuss the proposed upgrades with TMM representatives. Any
              concerns, questions, or suggestions from the franchisee will be
              addressed during this discussion.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Mutual Agreement:</div>
            <div className="text">
              Both parties, TMM and the franchisee, must reach a mutual
              agreement regarding the proposed upgrades. TMM will consider the
              franchisee's feedback and work collaboratively to ensure that the
              upgrades align with the best interests of both parties.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Acceptance or Rejection:</div>
            <div className="text">
              Following the discussion and considering any adjustments made
              based on feedback, the franchisee has the autonomy to either
              accept or reject the proposed upgrades. TMM respects the
              franchisee's decision and is committed to maintaining a positive
              and cooperative partnership.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Implementation:</div>
            <div className="text">
              If both parties agree on the upgrades, they will be implemented as
              per the communicated plan. TMM will provide support and resources
              needed for a smooth transition, ensuring that the franchisee
              benefits from the enhancements.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Ongoing Collaboration:</div>
            <div className="text">
              The process of package upgrades is an ongoing aspect of our
              collaboration. TMM remains open to feedback and suggestions from
              franchisees even after the implementation of upgrades. This
              continuous feedback loop helps in refining and optimising our
              packages for the benefit of all.
            </div>
          </div>

          <div className="text">
            At TMM, we believe that collaboration and shared decision-making
            contribute to the success and satisfaction of our franchise
            partners. Our commitment to transparent communication ensures that
            franchisees are well-informed and actively involved in the evolution
            of their business package.
          </div>
        </section>
        <section className="agreement-section territory-protection-section">
          <div className="heading-section">
            <div className="heading">Exclusive Territory Protection</div>
            <div className="text">
              At The Momos Mafia (TMM), we understand the importance of securing
              your business territory. To provide you with a competitive edge
              and foster your business growth, we offer exclusive territory
              protection through our Fencing Agreement. Here's a detailed
              overview of what this agreement entails:
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">One Franchise, One Territory:</div>
            <div className="text">
              TMM commits not to grant any other franchise within a radius of
              1km from your cart location. This ensures that you have exclusive
              rights to operate and serve customers in that designated area.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Safeguarding Your Market Presence:</div>
            <div className="text">
              By establishing this exclusive territory, we aim to protect your
              market presence and prevent any potential competition from other
              TMM franchises in the nearby vicinity. This strategic approach
              enhances your chances of building a loyal customer base in the
              defined area.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Ensuring Business Stability:</div>
            <div className="text">
              Exclusive territory protection contributes to the stability and
              predictability of your business operations. It allows you to focus
              on serving your local customers without the concern of overlapping
              with other TMM franchises, fostering a conducive environment for
              growth.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Mutual Commitment:</div>
            <div className="text">
              This agreement reflects the mutual commitment between TMM and the
              franchisee. TMM is dedicated to supporting the success of each
              franchise, and providing an exclusive territory is a key element
              in nurturing a thriving business.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Clear Demarcation:</div>
            <div className="text">
              The 1km radius serves as a clear demarcation, outlining the
              boundaries of your exclusive territory. This transparency helps in
              avoiding misunderstandings and conflicts related to territorial
              rights.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Flexible Adaptations:</div>
            <div className="text">
              While the Fencing Agreement establishes a baseline for exclusive
              territory protection, TMM remains open to discussions regarding
              potential adaptations based on specific market dynamics, ensuring
              a fair and flexible approach.
            </div>
          </div>

          <div className="text">
            At TMM, we believe that providing our franchisees with a protected
            and exclusive territory is integral to their long-term success. The
            Fencing Agreement is designed to offer you peace of mind, allowing
            you to concentrate on delivering exceptional momo experiences to
            your local customers.
          </div>
        </section>
        <section className="agreement-section gst-billing-section">
          <div className="heading-section">
            <div className="heading">
              GST & Billing Policy at The Momos Mafia (TMM)
            </div>
            <div className="text">
              At The Momos Mafia, we adhere to a straightforward and transparent
              GST & Billing policy to ensure clarity and compliance. Here are
              the key aspects of our policy:
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title"> Taxable Invoices:</div>
            <div className="text">
              All invoices issued by The Momos Mafia are taxable. This means
              that the specified goods and services provided in the invoices are
              subject to Goods and Services Tax (GST) as per the prevailing tax
              rates determined by the government.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title">Compliance with GST Regulations:</div>
            <div className="text">
              Westrictly adhere to the GST regulations set forth by the
              government. Our invoicing practices align with the legal
              requirements, and we remain committed to conducting our business
              operations in compliance with the applicable tax laws.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title"> Transparent Billing:</div>
            <div className="text">
              Transparency is a core value at TMM, and our billing practices
              reflect this commitment. We provide clear and detailed invoices,
              allowing our franchisees to easily understand the components of
              the billing, including the applicable taxes.
            </div>
          </div>

          <div className="sub-heading-section">
            <div className="title"> GST Rates:</div>
            <div className="text">
              The GST rates applied to our invoices are in accordance with the
              prevailing tax rates for the specific goods and services covered
              in the transactions. Any changes in GST rates mandated by the
              government will be reflected in our billing.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Documentation and Record Keeping:</div>
            <div className="text">
              We maintain accurate and comprehensive records of all
              transactions, including invoices and supporting documentation.
              This ensures that our financial records are well-documented and
              available for scrutiny if required.
            </div>
          </div>
          <div className="sub-heading-section">
            <div className="title">Customer Support:</div>
            <div className="text">
              If franchisees have any queries or require clarification regarding
              our GST & Billing policy, our customer support team is readily
              available to assist. We believe in fostering open communication
              and providing the necessary support to our valued partners.
            </div>
          </div>

          <div className="text">
            {" "}
            Our GST &Billing policy is designed to align with legal
            requirements, promote transparency, and facilitate a smooth
            financial interaction between The Momos Mafia and our franchisees.
            Weappreciate the cooperation of our partners in adhering to these
            guidelines for a harmonious business relationship.
          </div>
        </section>
        <section className="agreement-section last-section">
          <div className="text">
            {" "}
            I, ___________________, hereby acknowledge and accept all the terms
            and conditions outlined in the franchise document for The Momos
            Mafia. I am committed to adhering to and following each point
            mentioned in the agreement, understanding the responsibilities and
            expectations set forth by The Momos Mafia.
          </div>
          <div className="text">
            This declaration confirms the acceptance and commitment of the
            franchisee to comply with the terms of the franchise agreement with
            The Momos Mafia.
          </div>
        </section>
      </div>
    </div>
  );
};

export default Policy;
