import React from "react";
import "./HomeBanner.scss";
import banner_cart from "../../assets/images/banner_cart-img.png";
import chest_freezer from "../../assets/images/chest-freezer.png";
import youtube_icon from "../../assets/images/youtube_icon.svg";
import coma from "../../assets/images/coma.svg";
import { CustomButton as Button } from "../../CommonComponents/button/Button";
import logo from "../../assets/images/momo_logo.svg";

const card_data = [
    {
        id: 1,
        card_icon: "🚫",
        card_text: "Zero Franchise Fee"
    },
    {
        id: 2,
        card_icon: "💸",
        card_text: "Zero Royalty on Sales"
    },
    {
        id: 3,
        card_icon: "💰",
        card_text: "ROI in 2-3 Months"
    },
    {
        id: 4,
        card_icon: "🚀",
        card_text: "Starts in 7-10 days"
    },
    {
        id: 5,
        card_icon: "🗑️",
        card_text: "Low Wastage Model"
    }
]
const HomeBanner = () => {
  return (
    <div className="banner-section">
      <div className="banner-container">
        <div className="top_section max-width">
          <div className="left_side">
            <div className="work_conatiner">
              <img src={coma} alt="coma" className="coma" />
              <div className="work_btn">
                <img src={youtube_icon} alt="youtube" /> How it works
              </div>
            </div>
            <div className="banner_text">
              Get Momo Cart Franchise starting from Rs. 65,000 & earn Rs
              25,000/Month*
            </div>
            <div className="button_container">
              <Button
                customBtnClass="primaryBtn"
                title="Apply For Franchise"
               
              />
              <Button
                customBtnClass="SecondaryBtn"
                title="Book a Meeting"
              
              />
            </div>
          </div>
          <div className="right_side">
            <img
              src={chest_freezer}
              alt="chest_freezer"
              className="chest_freezer"
            />
            <img src={banner_cart} alt="banner_cart" className="banner_cart" />
          </div>
        </div>
        <div className="bottom_section max-width">
           <div className="cards">
           {
            card_data.map((i,n) => (
                <div className="card" key={n}>
                <div className="card_icon">{i.card_icon}</div>
                <div className="card_text">{i.card_text}</div>
            </div>
            ))
           }
           </div>
        </div>
      </div>
      <div className="banner-mobile-container">

        <div className="top_section max-width">
          <div className="top_side">
            <img className="" src={logo} alt="logo" />
          <div className="banner_text">
              Get Momo Cart Franchise starting from Rs. 65,000 & earn Rs
              25,000/Month*
            </div>
              <div className="work_btn">
                <img src={youtube_icon} alt="youtube" /> How it works
              </div>

          </div>
          <div className="middle_side">
            <img src={banner_cart} alt="banner_cart" className="banner_cart" />
            <div className="card_section">
           <div className="cards">
           {
            card_data.map((i,n) => (
                <div className="card" key={n}>
                <div className="card_icon">{i.card_icon}</div>
                <div className="card_text">{i.card_text}</div>
            </div>
            ))
           }
           </div>
        </div>
          </div>
          <div className="button_container">
              <Button
                customBtnClass="primaryBtn"
                title="Apply For Franchise"
                
              />
              <Button
                customBtnClass="SecondaryBtn"
                title="Book a Meeting"
              
              />
            </div>
        </div>
        
        
      </div>
    </div>
  );
};

export default HomeBanner;
