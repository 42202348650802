import React, { useEffect, useState } from "react";
import "./SatisfiedPartners.scss";
import data from "./data.json";
import img1 from "../../assets/images/Vector-img-1.svg";
import img2 from "../../assets/images/vector-img-2.svg";

const SatisfiedPartners = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const goToNextSlide = () => {
    const newIndex = (currentSlide + 1) % data.length;
    setCurrentSlide(newIndex);
  };

  const goToPrevSlide = () => {
    const newIndex = (currentSlide - 1 + data.length) % data.length;
    setCurrentSlide(newIndex);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevIndex) =>
        prevIndex === data.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts
    };
  }, [data]);

  return (
    <div className="SatisfiedPartners-section">
      <div className="SatisfiedPartners-container max-width">
        <div className="heading">Let’s Hear Directly from Our <br /> Satisfied Franchise Partners</div>
        <div className="carousel-section">
          <div className="left_side">
            {data.map((item, index) => (
              <>
                {currentSlide == index ? (
                  <div
                  className= "img-section">
                    <img src={item.img} alt="img" />
                  </div>
                ) : (
                  ""
                )}
              </>
            ))}
             
          </div>
          <div className="right_side">
             {data.map((item, index) => (
              <>
                {currentSlide == index ? (
                   <div  className="details-section">
                
                   <div className="card_text">{item.desc}</div>
                   <div className="card_name">
                     {item.name} |{" "}
                     <span className="card_job-title">{item["job-title"]}</span>
                   </div>
                 </div>
                ) : (
                  ""
                )}
              </>
            ))}
            <div className="button-section">
            <div onClick={goToPrevSlide}>
              <img src={img2} alt="img" />
            </div>
            <div>|</div>
            <div onClick={goToNextSlide}>
            <img src={img1} alt="img" />
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SatisfiedPartners;



