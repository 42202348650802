import React, { useState } from "react";
import "./LocationCard.scss";
import LocationCardNavigationItem from "./LocationCardNavigationItem";
import Locationdata from "./locationData.json";
import CardItem from "./CardItem";
import { Button } from "antd";

const LocationCard = () => {
  const [activeItem, setActiveItem] = useState("All");

  // const [currentPage, setCurrentPage] = useState(1);
  // const recordsPerPage = 6;
  // const lastIndex = currentPage * recordsPerPage;
  // const firstIndex = lastIndex - recordsPerPage;
  // const records = Locationdata.slice(firstIndex, lastIndex);
  // const nPages = Math.ceil(Locationdata.length / recordsPerPage);
  // const numbers = [...Array(nPages + 1).keys()].slice(1);
  // const changeCPage = (id) => {
  //   setCurrentPage(id);
  // };
  // const prevPage = () => {
  //   if(currentPage !== firstIndex) {
  //     setCurrentPage(currentPage - 1)
  //   }
  // };
  // const nextPage = () => {
  //   if(currentPage !== lastIndex){
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

   const handleItemClick = (label) => {
    setActiveItem(label);
  };
  const Delhi_Data = Locationdata.filter((item) => item.state === "Delhi");
  const Haryana_Data = Locationdata.filter((item) => item.state === "Haryana");
  const UttarPradesh_Data = Locationdata.filter(
    (item) => item.state === "UttarPradesh"
  );
  return (
    <>
      <div className="top-section">
        <div className="left_side">
          <LocationCardNavigationItem
            label="All"
            onClick={() => handleItemClick("All")}
            isActive={activeItem === "All"}
            span={`(${Locationdata.length})`}
          />
          <span>|</span>
          <LocationCardNavigationItem
            label="Delhi"
            onClick={() => handleItemClick("Delhi")}
            isActive={activeItem === "Delhi"}
            span={`(${Delhi_Data.length})`}
          />
          <span>|</span>
          <LocationCardNavigationItem
            label="Haryana"
            onClick={() => handleItemClick("Haryana")}
            isActive={activeItem === "Haryana"}
            span={`(${Haryana_Data.length})`}
          />
          <span>|</span>
          <LocationCardNavigationItem
            label="Uttar-Pradesh"
            onClick={() => handleItemClick("Uttar-Pradesh")}
            isActive={activeItem === "Uttar-Pradesh"}
            span={`(${UttarPradesh_Data.length})`}
          />
        </div>
        <div className="right_side">
          <Button className="search_btn">
            {/* <img src={lense} alt="lense" /> */}
            Search Nearest Outlet
          </Button>
        </div>
      </div>
      <div className="middle-section">
        {activeItem === "All" ? (
          <CardItem data={Locationdata} />
        ) : activeItem === "Delhi" ? (
          <CardItem data={Delhi_Data} />
        ) : activeItem === "Haryana" ? (
          <CardItem data={Haryana_Data} />
        ) : (
          <CardItem data={UttarPradesh_Data} />
        )}
      </div>
      {/* <div className="last-section">
        <nav>
          <ul className="pagination">
            <li className="page-item">
              <a href="#" className="page-link" onClick={prevPage}>
                Prev
              </a>
            </li>
            {numbers.map((n, i) => (
              <li
                className={`page-item ${currentPage === n ? "active" : ""}`}
                key={i}
              >
                <a href="#" className="page-item" onClick={() => changeCPage(n)}>
                  {n}
                </a>
              </li>
            ))}
            <li className="page-item">
              <a href="#" className="page-link" onClick={nextPage}>
                Next
              </a>
            </li>
          </ul>
        </nav>
      </div> */}
    </>
  );
};

export default LocationCard;
