import React, { useState } from 'react'
import Routers from '../../Routers/Routers'
import {Layout} from 'antd'
import { CustomHeader as Header } from '../header/Header'

export const CustomLayout = () => {
  const [bookingFlag, setBookingFlag] = useState(false);
  return (
    <Layout>
    <Header />
   <Routers />
  </Layout>
  )
}
