import React from "react";
import "./LocationSelection.scss";
import search_bar from "../../assets/images/search-bar.png";
import play_icon from "../../assets/images/play_icon.svg";
import search_icon from "../../assets/images/search_icon.png";
import { Col, Row } from "antd";
import { data1, data2 } from "./data";

const LocationSelection = () => {
  return (
    <div className="LocationSelection-section">
      <div className="LocationSelection-container">
        <div className="centered-container">
          <div className="title-style">
            <span className="prefixTextBold">Location</span>{" "}
            <span className="middleTextBold">Selection &</span>{" "}
            <span className="backgroundStyle"></span>
          </div>
        </div>
        <div className="recommendation-text">Recommendation</div>
        <div className="search-bar">
          <img src={search_bar} alt="searchbar" />
        </div>
        <div className="location-selection-text">
          The Momos Mafia team conducts a comprehensive location inspection{" "}
          <br />
          based on 8 factors before giving you the green light. 🕵️‍♂️🏙️
        </div>
        <div className="search-card">
          <div className="left_side">
            <div className="title">Location Inspection Test also helps us in determining -</div>
            <ol>
              <li>Product Offering </li>
              <li>Pricing Strategy </li>
              <li>Offline Marketing Campaign</li>
            </ol>
          </div>
          <div className="right_side">
            <img src={search_icon} alt="search_icon" />
          </div>
        </div>
        <div className="play-btn-container">
          <div className="play_btn">
            <img src={play_icon} alt="play_icon" />
          </div>
          <div className="play_btn-text">
            What is location Inspection & how it is done
          </div>
        </div>

        <div className="recommendation-card_content">
          <Row gutter={[16, 16]}>
            {data1.map((item) => (
              <Col xs={12} sm={12} xl={6}>
                <div className={`recommendation-card recommendation-card_${item.id}`}>
                  <div className="num_text">{item.num}</div>
                  <div className="insider_card">
                    <div className="item_title">{item.title}</div>
                    <div className="item_text">{item.text}</div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>

        <div className="recommendation-cards">
{
    data2.map((item,index) => (
        <div className="card" key={index}>
 <div className="item_title">{item.title}</div>
                    <div className="item_text">{item.text}</div>
        </div>
    ))
}
        </div>

      </div>
    </div>
  );
};

export default LocationSelection;
