import React from "react";
import "./TechnologyInfrastructure.scss";
import checkpoint1 from "../../assets/images/checkpoint-2.svg";
import checkpoint2 from "../../assets/images/checkpoint-1.svg";
import ai_icon from "../../assets/images/ai_icon.png";

const TechnologyInfrastructure = () => {
  return (
    <div className="TechnologyInfrastructure-section">
      <div className="TechnologyInfrastructure-container max-width">
        
        <div className="centered-container">
      <div className="title-style">
        <span className="prefixTextBold">Technology</span>{" "}
        <span className="middleTextBold">Infrasture</span>{" "}
        <span className="backgroundStyle"></span>
      </div>
    </div>
        <div className="technology-container">
          <div className="technology-left_side">
            <div className="left-top-section">
              <div className="title">
                Momo <span>quality check</span>
              </div>
              <div className="text">AI Based Momos QC system</div>
            </div>
            <div className="middle-section">
                <img src={ai_icon} alt="ai" />
            </div>
            <div className="bottom-section">
              <div className="content">
                <div className="left">
                  <img src={checkpoint1} alt="checkpoint" />
                </div>
                <div className="right">
                  Each and every momo is passed through{" "}
                  <span>12 checkpoints</span> before it gets delivered to you{" "}
                </div>
              </div>
              <div className="content">
                <div className="left">
                  <img src={checkpoint2} alt="checkpoint" />
                </div>
                <div className="right">
                  {" "}
                  Checked on <span> uniformity, hygienic</span> , and many other
                  checkpoints{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="technology-right_side">
            <div className="ordering-app-btn">Business Management and ordering app</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnologyInfrastructure;
