import React from "react";
import "./HomePage.scss";
import TrainingSupport from "../../Components/trainingSupport/TrainingSupport";
import HomeBanner from "../../Components/homeBanner/HomeBanner";
import AboutTheBrand from "../../Components/AboutTheBrand/AboutTheBrand";
import OurOutlets from "../../Components/OurOutlets/OurOutlets";
import BrandGrowth from "../../Components/BrandGrowth/BrandGrowth";
import FranchiseOverview from "../../Components/FranchiseOverview/FranchiseOverview";
import ProductRange from "../../Components/ProductRange/ProductRange";
import FssaiRegistration from "../../Components/FssaiRegistration/FssaiRegistration";
import RowMaterialDelivery from "../../Components/RowMaterialDelivery/RowMaterialDelivery";
import TechnologyInfrastructure from "../../Components/TechnologyInfrastructure/TechnologyInfrastructure";
import LowRisk from "../../Components/LowRisk/LowRisk";
import CapitalInvestment from "../../Components/CapitalInvestment/CapitalInvestment";
import SatisfiedPartners from "../../Components/SatisfiedPartners/SatisfiedPartners";
import LocationSelection from "../../Components/LocationSelection/LocationSelection";
import { CustomFooter as Footer } from "../../CommonComponents/footer/Footer";
import SocialMedia from "../../Components/SocialMedia/SocialMedia";

const HomePage = () => {
  return (
    <div className="home-section">
      <HomeBanner />
      <AboutTheBrand />
      <OurOutlets />
      <BrandGrowth />
      <SatisfiedPartners />
      <FranchiseOverview />
      <CapitalInvestment />
      <ProductRange />
      <LocationSelection />
      <TrainingSupport />
      <SocialMedia />
      <FssaiRegistration />
      <RowMaterialDelivery />
      <TechnologyInfrastructure />
      <LowRisk />
      <Footer />
    </div>
  );
};

export default HomePage;
