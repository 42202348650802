import React from 'react'
import "./LocationPage.scss";
import LocationCard from '../../Components/Location-card/LocationCard'

const LocationPage = () => {
  return (
    <div className='cart-location-section'> 
     <div className="cart-location-container max-width">
        <div className="cart-location-heading">The Momos Mafia Outlets</div>
        <div className="cart-location-subheading">We currently have over 12 Franchise Outlets across Delhi NCR & 3 other states.</div>
        <LocationCard />
     </div>
    </div>
  )
}

export default LocationPage
