export const data1 = [
    {
      id: "1",
      title: "Local Marketplace",
      text: "High footfall areas, especially during weekends.",
      num: "01",
    },
    {
      id: "2",
      title: "College & Universities",
      text: "Campus canteens & entry gates for a younger crowd.",
      num: "02",
    },
    {
      id: "3",
      title: "Office Area",
      text: "Business &  industrial zones for weekday lunches.",
      num: "03",
    },
    {
      id: "4",
      title: "Tourist Spots",
      text: "Near popular tourist attractions for travelers.",
      num: "04",
    }
    
  ];


  export const data2 = [
    {
      id: "1",
      title: "Railway Stations ",
      text: "Ideal for commuters in need of a quick snack.",

    },
    {
      id: "2",
      title: "Bus Terminals",
      text: "Ideal for commuters in need of a quick snack.",

    },
    {
      id: "3",
      title: "Residential Areas",
      text: "Close to neighborhoods, particularly in the evening",
  
    },
    {
      id: "4",
      title: "Parks & Recreation Areas",
      text: "Attract visitors in parks during evenings and weekends",
   
    },
    {
      id: "5",
      title: "High Footfall Streets",
      text: "Choose busy streets, especially in popular markets."
    },
    {
      id: "6",
      title: "Metro Stations",
      text: "Near metro stations to serve commuters in a hurry."
    },
  ]
  