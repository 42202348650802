import "./App.scss";
import {CustomLayout as Layout} from "./CommonComponents/layout/Layout";


function App() {
  return (
    <Layout />
    
    
  );
}

export default App;
