import React from 'react';
import "./LowRisk.scss";
import momo from "../../assets/images/momo-bg-logo.png";
import { CustomButton as Button } from '../../CommonComponents/button/Button';



const LowRisk = () => {
  return (
    <div className='LowRisk-section'>
        <div className="LowRisk-container">
         <div className="logo">
            <img src={momo} alt='momo' />
         </div>
         <div className="title">Low Risk. <span>Good Profit.</span> Quick ROI.</div>
         <div className="text">Our franchise provides a low-risk, high-profit opportunity with quick returns <br />on investment, ensuring a lucrative and sustainable business venture.</div>
         <div className="button-section">
            <Button title="Apply For Franchise" customBtnClass="primaryBtn"  />
            <Button title="Book a meeting" customBtnClass="SecondaryBtn"  />
         </div>
        </div>
      
    </div>
  )
}

export default LowRisk
